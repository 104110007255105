import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GetDataPath,
  GridApi,
  IAggFunc,
  IAggFuncParams,
  SideBarDef,
} from "ag-grid-community";

import { useNavigate } from "react-router";

import { AgGridReact } from "ag-grid-react";

import { Box, Dialog, Slider } from "@mui/material";
import _ from "lodash";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import {
  tableCellStyle,
  tableEntryStyle,
} from "../../components/TableComponent";
import SearchInput from "../../components/shared/SearchInput";
import { useGetCompanyName, HStack, VStack } from "../../components/utils";
import { useAuthStore } from "../../store";

import { TimeLineRenderer } from "../finance/components/tables/BalanceSheetAGComponent";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import { agConfigDashboardSideBar } from "./AgGridConfig";
import {
  CustomToolTip,
  CustomCaptableTooltipComponent,
  RoundsToolTip,
  financeCustomToolTip,
} from "./customTooltip";
import { RoundModel, SubsidiaryCompanyDetails } from "../../types/CapTable";
import { DashBoardModel, CumulativeHolding } from "../../types/DashboardModel";
import { useCapTableFilterStore } from "../../store/capTableFilterStore";
import { useSelectedShareholderFilterStore } from "../../store/selectedShareholderTypeStore";
import {
  exportSecurityBasedReport,
  exportRoundReport,
  exportCaptableSummaryReport,
} from "../../api/Report";

import {
  getScenarioCaptableSummaryReport,
  getOwnershipOverViewReport,
} from "../../api/Reports";
import { filterString, sliderStyle } from "../../constants/DashboardConstants";
import {
  shareholderOrder,
  getShareholderValue,
  getShareholderTagCustomColor,
} from "../../constants/ShareholderConstants";
import {
  useGetSubsidiaryCompanies,
  useGetSubsidiaryCompanyCaptable,
} from "../../queries/captable";
import { useAgGridTableState } from "../../store/agGridTableStore";
import { GenericCumulativeHolding } from "../../types/GenericCaptableModel";
import {
  downloadExcel,
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { globalFilter, sort } from "../../utils/arrays";
import { _trimAll, classNames, limitString } from "../../utils/string";
import { useGetOnFilterState, usePostOnFilterState } from "./AgGridCacheQuery";
import ExportMultidateSheetDetails from "../../modals/ExportMultidateSheetDetails";
import GenericTableHeader from "../../shared/TableHeader";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { formatDisplayDate } from "../../utils/date";
import { ExportImport } from "../Utility/GrantsTable";
import { Action } from "../../components/shared/Dropdown";
import Tooltip from "../../components/shared/Tooltip";
import {
  AmountInvestedRender,
  DebenturesRender,
  EquitySharesRender,
  FdbPercentageRender,
  FdbSharesRender,
  NotesRender,
  PreferenceShareRender,
  RoundsRender,
  TotalInvestedAmountRender,
  WarrantsRender,
  shareholderNameRender,
} from "./AgGridCaptableComponent";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

interface MarkLabel {
  value: number;
  label: string;
}

export interface ShareholderTypeTotal {
  name: string;
  value: number;
}
function AgGridDashboardCaptable({
  cinNumber,
  allEventsDetail = [],
  isScenarioCaptable = false,
  companyId,
  markLabels,
  latestCaptable,
  roundModel,
}: {
  cinNumber?: string;
  allEventsDetail?: DashBoardModel[];
  isScenarioCaptable?: boolean;
  companyId: string;
  markLabels: MarkLabel[];
  latestCaptable: CumulativeHolding[];
  roundModel?: RoundModel;
}) {
  const gridApi = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const length = (allEventsDetail?.length || 1) - 1;
  const [captableData, setCapTableData] = useState<CumulativeHolding[]>([]);
  const [isSubsidiary, setIsSubsidiary] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [subsidiaryCompanyId, setSubsidiaryCompanyId] = useState<string>("");
  const [subsidiaryCompanies, setSubsidiaryCompanies] =
    useState<SubsidiaryCompanyDetails[]>();
  let _captableData: GenericCumulativeHolding[] =
    captableData.length > 0 ? captableData : latestCaptable;

  const [isFilterApplied, setIsFilterApplied] = useState(false);
  function customSort(arr: CumulativeHolding[]): CumulativeHolding[] {
    const order: { [key: string]: number } = shareholderOrder;

    arr.sort((a, b) => {
      const aIndex = order[(a.type || "").toLowerCase()] || Number.MAX_VALUE;
      const bIndex = order[(b.type || "").toLowerCase()] || Number.MAX_VALUE;
      return aIndex - bIndex;
    });

    return arr;
  }

  function customTotalSort(
    arr: ShareholderTypeTotal[]
  ): ShareholderTypeTotal[] {
    const order: { [key: string]: number } = shareholderOrder;

    arr.sort((a, b) => {
      const aIndex = order[(a.name || "").toLowerCase()] || Number.MAX_VALUE;
      const bIndex = order[(b.name || "").toLowerCase()] || Number.MAX_VALUE;
      return aIndex - bIndex;
    });

    return arr;
  }

  const currency = getCurrencyType();
  const [currentPage, setCurrentPage] = useState(1);

  const [totalChart, setTotalChart] = useState<{
    name: string;
    type: string;
  }>({
    name: "FDB Shares",
    type: "fdbShares",
  });
  const [totalValue, setTotalValue] = useState<ShareholderTypeTotal[]>([]);
  const [checkIsDataAvailable, setCheckIsDataAvailable] = useState(false);
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: string;
    type?: string;
  }>({
    open: false,
  });

  // store;
  const capTablefilterStore = useCapTableFilterStore();
  const [open, setOpen] = useState(false); // State to manage groupDefaultExpanded

  // api;
  const { data: subsidiaryCompaniesList } = useGetSubsidiaryCompanies();
  const {
    refetch: getSubsidiaryCompanyCaptable,
    data: subsidiaryCompanyCaptable,
  } = useGetSubsidiaryCompanyCaptable(subsidiaryCompanyId || "");

  useEffect(() => {
    if (allEventsDetail.length > 1) {
      capTablefilterStore.reset();
      updateRange(length);
      capTablefilterStore.setRound(allEventsDetail[length].roundSummary.name);
      capTablefilterStore.setDateOfInvestment(
        new Date(allEventsDetail[length].eventDate)
      );
    }
    setCount(allEventsDetail?.length || 0 - 1);
  }, [allEventsDetail]);

  useEffect(() => {
    if (subsidiaryCompaniesList && Array.isArray(subsidiaryCompaniesList))
      setSubsidiaryCompanies(subsidiaryCompaniesList);
  }, [subsidiaryCompaniesList]);

  useEffect(() => {
    if (subsidiaryCompanyCaptable) {
      setCapTableData(customSort(subsidiaryCompanyCaptable));
    }
  }, [subsidiaryCompanyCaptable]);

  const updateRange = (countNumber: any) => {
    if (allEventsDetail) {
      setCount(countNumber);
      setCapTableData(customSort(allEventsDetail[countNumber].capTable));
    }
  };

  _captableData = useMemo(() => {
    try {
      if (!_captableData) return [];

      const filterResult = globalFilter(_captableData, "", filterString)
        .filter(
          (shareHolder) =>
            !capTablefilterStore.typeOfShareHolder ||
            _trimAll((shareHolder.type || "").toLowerCase()) ===
              _trimAll(capTablefilterStore.typeOfShareHolder.toLowerCase())
        )
        .filter(
          (shareHolder) =>
            !capTablefilterStore.actionableInsightFilters[0].enabled ||
            (shareHolder.fdbPercentage || 0) > 0.05
        )
        .filter(
          (shareHolder) =>
            !capTablefilterStore.actionableInsightFilters[1].enabled ||
            shareHolder.residential !== "India"
        )
        .filter(
          (shareHolder) =>
            capTablefilterStore.shareholder.includes(shareHolder.type || "") ||
            capTablefilterStore.shareholder.length === 0
        );

      return filterResult;
    } catch (e) {
      return _captableData;
    }
  }, [_captableData, capTablefilterStore, latestCaptable]);

  const companyName = useGetCompanyName();
  let fileName;
  async function handleActions(action: Action) {
    if (action.disabled) return;

    if (action.name === "Security Based Report") {
      await exportSecurityBasedReport("").then((res) => {
        fileName = `Security Based Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Round Based Report") {
      await exportRoundReport("").then((res) => {
        fileName = `Round Based Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Export Summary Report") {
      if (roundModel) {
        getScenarioCaptableSummaryReport(
          companyId,
          roundModel.id || "",
          true
        ).then((data) => {
          const fileName = `${roundModel.name} scenario captable`;
          convertBase64ToBlob(
            data.data,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ).then((downloadData) => {
            downloadBlobObject(downloadData, fileName);
          });
        });
        return;
      }
      setDialog({
        open: true,
        data: "Export Summary Report",
        type: "Export Summary Report",
      });
    } else if (action.name === "Allotment Window Report") {
      setDialog({
        open: true,
        data: "Allotment Window Report",
      });
    } else if (action.name === "Ownership Detailed Report") {
      await getOwnershipOverViewReport("").then((res) => {
        fileName = `Ownership Detailed Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    }
  }

  useEffect(() => {
    const newCaptableData = captableData.map((element) => ({
      ...element,
      fdbPercentage: parseFloat(
        ((element.fdbPercentage || 0) * 100).toFixed(2)
      ),
    }));
    const total = _.groupBy(newCaptableData, "type");
    const totalVal: ShareholderTypeTotal[] = [];

    for (const investorType of Object.keys(total)) {
      const shareholderType: ShareholderTypeTotal = {
        name: investorType,
        value: _.sumBy(total[investorType], totalChart.type),
      };
      totalVal.push(shareholderType);
    }
    const sortedResults = sort(totalVal, "value", false);
    setTotalValue(customTotalSort(sortedResults));
    const count = totalVal.find((total) => total.value > 0);
    const countValue = (count?.name || "").length > 0;
    setCheckIsDataAvailable(countValue);
  }, [totalChart, _captableData]);

  const firstRoundMarkIndex = Math.floor((allEventsDetail.length - 1) / 4);
  const secondRoundMarkIndex = Math.floor((allEventsDetail.length - 1) / 2);
  const thirdRoundMarkIndex = Math.floor(
    ((allEventsDetail.length - 1) / 4) * 3
  );

  const currencySymbol = getCurrencySymbol();
  const agTableStore = useAgGridTableState();
  const userId = useAuthStore().user?.userId ?? 0;

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const { refetch } = useGetOnFilterState(`${userId}`, companyId);

  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.captableColumnModel);
      setFilterSetting(data.data?.captableFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        captableColumnModel: columnState,
        captableFilterModel: filterState,
      },
    });
  };

  const componentsRegistery = useMemo(
    () => ({
      shareholderName: memo(shareholderNameRender),
      equity: memo(EquitySharesRender),
      roundIdentifiers: memo(RoundsRender),
      preference: memo(PreferenceShareRender),
      debentures: memo(DebenturesRender),
      amountInvested: memo(AmountInvestedRender),
      totalAmount: memo(TotalInvestedAmountRender),
      fdbShares: memo(FdbSharesRender),
      fdbPercentage: memo(FdbPercentageRender),
      warrants: memo(WarrantsRender),
      notes: memo(NotesRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    if (!cinNumber) {
      handleEventForTracking({ eventName: `Shareholder Name` });
      navigate(`/shareholders/individual/${id}`);
    }
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      enableCellClick: true,

      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Rounds",
        field: "rounds",
        cellRenderer: "roundIdentifiers",
        initialWidth: 250,
        cellStyle: {
          "padding-top": "5px",
          "line-height": "20px",
        },
        sortable: false,
        filter: "agSetColumnFilter",
        valueGetter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
        valueFormatter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
        menuTabs: ["filterMenuTab"],
        tooltipField: "roundsTooltipField",
        tooltipComponent: (params: any) => {
          if (params.data.orgHierarchy[0] !== "Total") {
            return <RoundsToolTip {...params} />;
          }
          return <></>;
        },
      },
      {
        headerName: "Equity",
        field: "equityShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "equity",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipEquity",
        hide: false,
        tooltipComponent: (params: any) => (
          <CustomCaptableTooltipComponent {...params} />
        ),
      },
      {
        headerName: "Preference",
        field: "prefShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "preference",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPreference",
        hide: false,
        suppressToolPanel: true,
        tooltipComponent: (params: any) => (
          <CustomCaptableTooltipComponent {...params} />
        ),
      },
      {
        headerName: "Debentures",
        field: "debentures",
        filter: "agNumberColumnFilter",
        cellRenderer: "debentures",
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipDebentures",
        tooltipComponent: (params: any) => (
          <CustomCaptableTooltipComponent {...params} />
        ),
      },
      {
        headerName: "Warrants",
        field: "warrants",
        filter: "agNumberColumnFilter",
        cellRenderer: "warrants",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipWarrants",
        hide: true,
        tooltipComponent: (params: any) => (
          <CustomCaptableTooltipComponent {...params} />
        ),
      },

      {
        headerName: "Notes",
        field: "notes",
        filter: "agNumberColumnFilter",
        cellRenderer: "notes",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNotes",
        hide: true,
        tooltipComponent: (params: any) => (
          <CustomCaptableTooltipComponent {...params} />
        ),
      },
      {
        headerName: "FDB Shares",
        field: "fdbShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "fdbShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipFdbShares",
        hide: false,
        tooltipComponent: (params: any) => (
          <CustomCaptableTooltipComponent {...params} />
        ),
      },
      {
        headerName: `Total Investment (${currencySymbol})`,
        field: "totalAmount",
        filter: "agNumberColumnFilter",
        cellRenderer: "totalAmount",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipTotalAmountInvested",
        hide: true,
        tooltipComponent: (params: any) => (
          <CustomCaptableTooltipComponent {...params} />
        ),
      },
      {
        headerName: `Paid up Amount (${currencySymbol})`,
        field: "amountInvested",
        filter: "agNumberColumnFilter",
        cellRenderer: "amountInvested",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInvested",
        hide: true,
        tooltipComponent: (params: any) => (
          <CustomCaptableTooltipComponent {...params} />
        ),
      },
      {
        headerName: `FDB %`,
        field: "fdbPercentage",
        filter: "agNumberColumnFilter",
        cellRenderer: "fdbPercentage",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipFdbPercentage",
        hide: false,
        tooltipComponent: (params: any) => (
          <CustomCaptableTooltipComponent {...params} />
        ),
      },
    ],
    []
  );

  const [groupDefaultExpanded, setGroupDefaultExpanded] = useState(0);

  const currencyType = getCurrencyType();

  const rowData = useMemo(() => {
    if (!_captableData) return [];
    return _captableData.flatMap((template: any, ind: number) => {
      const shareholderName = {
        name: template.name,
        type: getShareholderValue(template?.type ?? "") ?? "",
      };
      const id = template.id;
      const shareholderType = getShareholderValue(template?.type ?? "") ?? "";
      const rounds = template.roundIdentifierDetails;
      const equityShares = Number(template.equityShares);
      const tooltipEquity =
        template?.equityShares?.toLocaleString(currencyType);
      const prefShares = template.prefShares;
      const tooltipPreference =
        template?.prefShares?.toLocaleString(currencyType);

      const debentures = template.debentures;
      const isdebt = template.isDebt;
      const tooltipDebentures =
        template?.debentures?.toLocaleString(currencyType);
      const notes = template.notes;
      const tooltipNotes = template?.notes?.toLocaleString(currencyType);
      const warrants = template.warrants;
      const tooltipWarrants = template.warrants;
      const amountInvested = template.investedAmount;
      const tooltipAmountInvested =
        template?.investedAmount?.toLocaleString(currencyType);
      const fdbShares = template.fdbShares;
      const tooltipFdbShares =
        template?.fdbShares?.toLocaleString(currencyType);
      const fdbPercentage = Number(Math.abs(template?.fdbPercentage!) * 100);
      const tooltipFdbPercentage = Math.abs(template?.fdbPercentage!) * 100;
      const isEquityPartlyPaid = template.isEquityPartlyPaid;
      const isPreferencePartlyPaid = template.isPreferencePartlyPaid;
      const isDebtPartlyPaid = template.isDebtPartlyPaid;
      const roundsTooltipField = template.roundIdentifierDetails;
      const totalAmount = template.totalAmount;
      const tooltipTotalAmountInvested =
        template?.totalAmount?.toLocaleString(currencyType);

      const totalEquityShares = _captableData.reduce((acc: any, item: any) => {
        if (template.type === item.type) return acc + item.equityShares;
        else {
          return acc;
        }
      }, 0);

      const totalDebentures = _captableData.reduce((acc: any, item: any) => {
        if (template.type === item.type) return acc + item.debentures;
        else {
          return acc;
        }
      }, 0);

      const totalPrefShares = _captableData.reduce((acc: any, item: any) => {
        if (template.type === item.type) return acc + item.prefShares;
        else {
          return acc;
        }
      }, 0);

      const totalTotalAmount = _captableData.reduce((acc: any, item: any) => {
        if (template.type === item.type) return acc + item.totalAmount;
        else {
          return acc;
        }
      }, 0);

      const totalWarrants = _captableData.reduce((acc: any, item: any) => {
        if (template.type === item.type) return acc + item.warrants;
        else {
          return acc;
        }
      }, 0);

      const totalNotes = _captableData.reduce((acc: any, item: any) => {
        if (template.type === item.type) return acc + item.notes;
        else {
          return acc;
        }
      }, 0);

      const totalFdbShares = _captableData.reduce((acc: any, item: any) => {
        if (template.type === item.type) return acc + item.fdbShares;
        else {
          return acc;
        }
      }, 0);

      const totalAmountInvested = _captableData.reduce(
        (acc: any, item: any) => {
          if (template.type === item.type) return acc + item.investedAmount;
          else {
            return acc;
          }
        },
        0
      );

      const totalFdbPercentage = _captableData.reduce((acc: any, item: any) => {
        if (template.type === item.type)
          return acc + Math.abs(item?.fdbPercentage!) * 100;
        else {
          return acc;
        }
      }, 0);

      const roundIdentifiers: any = {};
      const groupedData = _.groupBy(_captableData, "type");

      for (const [groupType, groupData] of Object.entries(groupedData)) {
        const roundIdentifierDetails = groupData.flatMap(
          (item) => item.roundIdentifierDetails
        );

        roundIdentifiers[groupType.toLowerCase()] = roundIdentifierDetails;
      }

      return [
        {
          orgHierarchy: [shareholderType],
          id,
          cin: cinNumber,
          roundIdentifiers,
          roundsTooltipField,
          shareholderName,
          equityShares: totalEquityShares,
          tooltipEquity,
          tooltipPreference,
          debentures: totalDebentures,
          tooltipDebentures,
          prefShares: totalPrefShares,
          tooltipFdbShares,
          warrants: totalWarrants,
          tooltipWarrants,
          notes: totalNotes,
          tooltipNotes,
          fdbShares: totalFdbShares,
          fdbPercentage: totalFdbPercentage,
          tooltipFdbPercentage,
          amountInvested: totalAmountInvested,
          tooltipAmountInvested,
          isPreferencePartlyPaid,
          isEquityPartlyPaid,
          isDebtPartlyPaid,
          currencySymbol,
          currencyType: currency,
          isdebt,
          totalAmount: totalTotalAmount,
          tooltipTotalAmountInvested,
        },
        {
          orgHierarchy: [shareholderType, shareholderName.name],
          id,
          rounds,
          cin: cinNumber,
          roundsTooltipField,
          equityShares,
          shareholderName,
          prefShares,
          tooltipPreference,
          debentures,
          tooltipDebentures,
          fdbShares,
          tooltipFdbShares,
          fdbPercentage,
          tooltipFdbPercentage,
          warrants,
          tooltipWarrants,
          notes,
          amountInvested,
          tooltipAmountInvested,
          tooltipEquity,
          isPreferencePartlyPaid,
          isEquityPartlyPaid,
          isDebtPartlyPaid,
          currencySymbol,
          currencyType: currency,
          isdebt,
          totalAmount,
          tooltipTotalAmountInvested,
        },
      ];
    });
  }, [
    captableData,
    allEventsDetail,
    capTablefilterStore,
    groupDefaultExpanded,
    open,
    latestCaptable,
  ]);

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.68) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.48) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.42) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    const template = cellParams.data;
    if (
      cellParams.column.getColId() === "ag-Grid-AutoColumn" &&
      cellParams.node.level !== 0 &&
      cellParams.value !== "Total"
    ) {
      onClickShareholderName(template.id);
    }
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterFilter.flatMap(
      (element: any) => element.childrenAfterFilter
    );

    const totalEquity = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.data.equityShares,
      0
    );

    const totalPreferenceShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.data.prefShares,
      0
    );

    const totalDebentures = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.data.debentures,
      0
    );

    const totalWarrants = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.data.warrants,
      0
    );

    const totalNotes = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.data.notes,
      0
    );

    const totalFdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.data.fdbShares,
      0
    );

    const totalAmount = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.data.amountInvested,
      0
    );

    const totalPercentage = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.data.fdbPercentage,
      0
    );

    const totalTotalAmount = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.data.totalAmount,
      0
    );

    api.setPinnedBottomRowData([
      {
        orgHierarchy: ["Total"],
        equityShares: totalEquity,
        tooltipEquity: totalEquity?.toLocaleString(currencyType),
        round: undefined,
        prefShares: totalPreferenceShares,
        tooltipPreference: totalPreferenceShares?.toLocaleString(currencyType),
        debentures: totalDebentures,
        tooltipDebentures: totalDebentures.toLocaleString(currencyType),
        notes: totalNotes,
        tooltipNotes: totalNotes.toLocaleString(currencyType),
        warrants: totalWarrants,
        tooltipWarrants: totalWarrants.toLocaleString(currencyType),
        amountInvested: totalAmount,
        tooltipAmountInvested: totalAmount.toLocaleString(currencyType),
        fdbShares: totalFdbShares,
        tooltipFdbShares: totalFdbShares.toLocaleString(currencyType),
        currencyType: currency,
        currency,
        fdbPercentage:
          Number(totalPercentage.toFixed(2)) > 99.98
            ? 100
            : totalPercentage.toFixed(2),
        tooltipFdbPercentage:
          Number(totalPercentage.toFixed(2)) > 99.98
            ? 100
            : totalPercentage.toFixed(2),
        roundsTooltipField: "87y980",
        totalAmount: totalTotalAmount,
        tooltipTotalAmountInvested:
          totalTotalAmount.toLocaleString(currencyType),
      },
    ]);
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([]);

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };

  const filterDataList: any = [];
  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);
    const data = grid.api.getModel().rowsToDisplay;
    // const filteredData = grid.api
    //   .getModel()
    //   .rowsToDisplay?.flatMap((node: any) =>
    //     node.data.childrenAfterFilter.map((child: any) => child.data)
    //   );

    const traverseNodes = (parentNode: any) => {
      parentNode.childrenAfterFilter.forEach((node: any) => {
        node.childrenAfterFilter.forEach((node: any) =>
          filterDataList.push(node)
        );
      });
    };

    const model = grid.api.getModel();
    if (model && model.rootNode) {
      traverseNodes(model.rootNode);
    }

    setFilteredRowData(filterDataList);
    setPinnedBottomRowData(grid);
    uploadFilterAndColumn();
  };

  const CustomHeaderComponent = () => (
    <div>
      <HStack className="cursor-pointer" onClick={() => handleGroupToggle()}>
        <div className="pt-0.5">
          <ChevronDownIcon
            className={classNames(
              open ? "rotate-0" : "-rotate-90",
              "h-3 w-5 transform text-slate-500"
            )}
            strokeWidth={2.5}
          />
        </div>
        <span className="px-9">Group Name</span>
      </HStack>
    </div>
  );
  const handleGroupToggle = () => {
    if (open) {
      setOpen(!open);
      setGroupDefaultExpanded(0);
    } else {
      setOpen(!open);
      setGroupDefaultExpanded(-1);
    }
  };

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      minWidth: 300,
      menuTabs: [],
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        innerRenderer: shareholderNameRender,
        suppressCount: true,
        enableCellClick: true,
      },
      sortable: true,
      valueGetter: (params) =>
        params.data.orgHierarchy[params.data.orgHierarchy.length - 1],
      pinned: true,
      tooltipField: "tooltipEquity",

      headerComponent: CustomHeaderComponent,
      tooltipComponent: (params: any) => {
        if (
          params.data.orgHierarchy[0] !== "Total" &&
          params.node.level !== 0
        ) {
          return <CustomToolTip {...params} />;
        }
        return null;
      },
    }),
    [_captableData, groupDefaultExpanded, open]
  );

  const getDataPath = useMemo<GetDataPath>(
    () => (data: any) => data.orgHierarchy,
    []
  );

  return (
    <VStack
      className={`bg-white w-full border border-borderColor shadow-box rounded-lg ${
        isScenarioCaptable ? "" : "mt-8 shadow-box"
      }`}
    >
      {captableData.length > 0 && !isSubsidiary && allEventsDetail.length > 1 && (
        <div className="px-6 pt-2 mx-6 mt-6 border rounded-md shadow-box">
          <HStack className="justify-between">
            <p className="text-xxs">
              {allEventsDetail[0].roundSummary.name[0].toUpperCase() +
                allEventsDetail[0].roundSummary.name.slice(1)}
            </p>
            {allEventsDetail.length > 10 && (
              <p className="text-xxs">
                {allEventsDetail[
                  firstRoundMarkIndex
                ].roundSummary.name[0].toUpperCase() +
                  allEventsDetail[firstRoundMarkIndex].roundSummary.name.slice(
                    1
                  )}
              </p>
            )}
            {(allEventsDetail.length % 2 !== 0 ||
              allEventsDetail.length > 10) && (
              <p className="text-xxs">
                {allEventsDetail[
                  secondRoundMarkIndex
                ].roundSummary.name[0].toUpperCase() +
                  allEventsDetail[secondRoundMarkIndex].roundSummary.name.slice(
                    1
                  )}
              </p>
            )}
            {allEventsDetail.length > 10 && (
              <p className="text-xxs">
                {allEventsDetail[
                  thirdRoundMarkIndex
                ].roundSummary.name[0].toUpperCase() +
                  allEventsDetail[thirdRoundMarkIndex].roundSummary.name.slice(
                    1
                  )}
              </p>
            )}
            <p className="text-xxs">
              {allEventsDetail[
                allEventsDetail.length - 1
              ].roundSummary.name[0].toUpperCase() +
                allEventsDetail[
                  allEventsDetail.length - 1
                ].roundSummary.name.slice(1)}
            </p>
          </HStack>
          <Slider
            min={0}
            value={count}
            defaultValue={markLabels.length - 1}
            max={markLabels.length - 1}
            onChange={(e: any, countNumber: any) => {
              updateRange(countNumber);
            }}
            onChangeCommitted={(e: any, countNumber: any) => {
              if (allEventsDetail) {
                capTablefilterStore.setDateOfInvestment(
                  new Date(allEventsDetail[countNumber].eventDate)
                );
                capTablefilterStore.setRound(
                  allEventsDetail[countNumber].roundSummary.name
                );
              }
            }}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) =>
              allEventsDetail && (
                <div className="bg-transparent">
                  <p className="text-center">
                    {allEventsDetail[value].roundSummary?.name}
                  </p>
                  <p className="text-center">
                    (
                    {formatDisplayDate(
                      allEventsDetail[value].roundSummary?.date || new Date()
                    )}
                    )
                  </p>
                </div>
              )
            }
            marks={markLabels}
            sx={sliderStyle}
          />
        </div>
      )}

      <VStack className="min-h-full gap-4">
        <Dialog open={dialog.open} maxWidth="md">
          {dialog.type === "Export Summary Report" && (
            <ExportMultidateSheetDetails
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
              reportName={"Export Summary Report"}
            />
          )}
          {dialog.type !== "Export Summary Report" && (
            <ExportMultidateSheetDetails
              reportName={dialog.data || ""}
              companyId={companyId}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          )}
        </Dialog>

        <VStack className="gap-8 bg-white rounded-lg">
          <HStack className="items-center justify-between gap-4 py-2">
            <GenericTableHeader
              heading={"Cap Table"}
              subHeading={"Shareholders"}
              count={
                isFilterApplied
                  ? filteredRowData.length ?? 0
                  : _captableData.length ?? 0
              }
            />

            {/* {subsidiaryCompanies && subsidiaryCompanies.length > 0 && (
          <HStack className="shadow-lg hover:cursor-pointer">
            <div
              className={`border justify-center h-full pt-2 pb-2 px-2 rounded-l-md ${
                isSubsidiary
                  ? "bg-white text-[#464E5F]"
                  : "bg-[#E85936] text-white"
              }`}
            >
              <div
                className={`font-semibold text-base whitespace-nowrap h-full w-40 pl-3 pt-1 ${
                  isSubsidiary
                    ? "bg-white text-[#464E5F]"
                    : "bg-[#E85936] text-white"
                }`}
                onClick={() => {
                  setIsSubsidiary(false);
                  if (allEventsDetail) {
                    const length = (allEventsDetail?.length || 1) - 1;
                    setCapTableData(
                      customSort(allEventsDetail[length].capTable)
                    );
                  }
                }}
              >
                Parent Company
              </div>
            </div>
            <div className="justify-center h-full border rounded-r-md">
              <Tooltip
                text={
                  subsidiaryCompanies?.find(
                    (subsidiary) => subsidiary.companyId === subsidiaryCompanyId
                  )?.companyName || ""
                }
              >
                <select
                  className={`form-select rounded-none w-40 hover:cursor-pointer ${
                    !isSubsidiary
                      ? "bg-white text-[#464E5F]"
                      : "bg-[#E85936] text-white"
                  }`}
                  value={subsidiaryCompanyId}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      setIsSubsidiary(true);
                      setSubsidiaryCompanyId(e.target.value);
                      getSubsidiaryCompanyCaptable();
                    }
                  }}
                  onClick={(e) => {
                    if (subsidiaryCompanyId !== "") {
                      setIsSubsidiary(true);
                      setSubsidiaryCompanyId(subsidiaryCompanyId);
                      getSubsidiaryCompanyCaptable();
                    }
                  }}
                >
                  <option value={""}>{"Subsidiary"}</option>
                  {subsidiaryCompanies?.map((option, i) => (
                    <option
                      className="bg-white text-[#464E5F]"
                      key={i}
                      value={option.companyId}
                    >
                      {option.companyName}
                    </option>
                  ))}
                </select>
              </Tooltip>
            </div>
          </HStack>
        )} */}
            {/* </HStack> */}
            <HStack className="items-center justify-between w-full gap-4 px-2 py-2 lg:w-auto lg:justify-end">
              <SearchInput
                placeholder={`Search`}
                onChange={(e: any) => {
                  gridApi.current.api.setQuickFilter(e.target.value);
                }}
              />

              <div className="hidden md:block ">
                {!cinNumber && (
                  <ExportImport
                    actions={
                      isScenarioCaptable
                        ? [
                            {
                              name: "Export Summary Report",
                            },
                          ]
                        : [
                            {
                              name: "Security Based Report",
                            },
                            {
                              name: "Round Based Report",
                              disabled: true,
                            },
                            {
                              name: "Export Summary Report",
                            },
                            {
                              name: "Allotment Window Report",
                            },
                            {
                              name: "Ownership Detailed Report",
                            },
                          ]
                    }
                    onAction={(action) => handleActions(action)}
                  />
                )}
              </div>
              <HStack className="items-center justify-end gap-4 py-2">
                <IconCTAButton
                  value={"Columns"}
                  onClick={() => openToolPanel("columns")}
                  iconName={"fluent:column-triple-edit-20-regular"}
                  className={`px-4 font-medium items-center flex flex-row ${
                    isColumnOpen ? "text-orange-501" : "text-gray-400"
                  }`}
                />
                <IconCTAButton
                  value={"Filters"}
                  onClick={() => openToolPanel("filters")}
                  iconName={"material-symbols:filter-alt"}
                  className={`px-4 mr-4 font-medium items-center flex flex-row ${
                    isFilterOpen ? "text-orange-501" : "text-gray-400"
                  }`}
                />
              </HStack>
            </HStack>
          </HStack>
          {/* </HStack> */}
          <HStack className="justify-between w-full">
            <Box
              style={{
                height: setTableHeight(),
              }}
              className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
            >
              <AgGridReact
                sideBar={agConfigDashboardSideBar}
                ref={gridRef}
                onGridReady={(params) => {
                  // Store the grid API referen
                  gridApi.current = params;
                }}
                components={componentsRegistery}
                alwaysShowHorizontalScroll
                alwaysMultiSort
                animateRows={true}
                enableCharts={true}
                enableRangeSelection={true}
                defaultColDef={defaultColDef}
                onRowDataUpdated={setPinnedBottomRowData}
                onFilterChanged={onAgGridFilterChanged}
                onColumnResized={uploadFilterAndColumn}
                gridOptions={gridOptions}
                onColumnEverythingChanged={uploadFilterAndColumn}
                rowData={rowData}
                onCellClicked={handleCellClick}
                columnDefs={columnDefs}
                autoGroupColumnDef={autoGroupColumnDef}
                treeData={true}
                groupDefaultExpanded={groupDefaultExpanded}
                pagination={false}
                getDataPath={getDataPath}
                suppressRowTransform={true}
                suppressCopyRowsToClipboard={true}
                suppressCopySingleCellRanges={true}
                suppressCellFocus={true}
                suppressMenuHide={false}
                tooltipShowDelay={1000}
                tooltipInteraction={true}
                rowClass={"border-t border-dashed "}
                overlayNoRowsTemplate={
                  '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
                }
              ></AgGridReact>
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  );
}

export default AgGridDashboardCaptable;
