import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router";
import {
  useSubmitEmailForVerification,
  useSubmitOtpForVerification,
} from "../../queries/share";
import { HStack, decodeShareAuthToken } from "../../components/utils";
import { useShareAuthStore } from "../../store/useShareAuthStore";
import { useAuthStore } from "../../store";

export default function Example() {
  const navigate = useNavigate();

  const { id, email, otp } = useParams() as {
    id: string;
    email: string;
    otp: string;
  };

  const { setShareAuth } = useShareAuthStore();
  const [data, setData] = useState(true);
  const [otpNumber, setOtpNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const { mutate: submitEmailForVerification } =
    useSubmitEmailForVerification();
  const { mutate: submitOtpForVerification } = useSubmitOtpForVerification();
  useEffect(() => {
    if (email) setEmailId(email);
    if (otp) {
      setData(false);
      setOtpNumber(otp);
    }
  }, [email, otp]);

  const sendOtp = () => {
    if (id) {
      submitEmailForVerification(
        { data: { email: emailId }, shareId: id },
        {
          onSuccess: (data) => {
            setData(false);

            toast(`OTP sent to ${emailId} successfully!`, {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: (err: any) => {
            toast(err.response.data.errorMessage, {
              type: "error",
              autoClose: 2000,
            });
          },
        }
      );
    } else {
      toast("Please refresh page and try again", {
        type: "error",
        autoClose: 2000,
      });
    }
  };
  const sendCode = (otpNum: string) => {
    submitOtpForVerification(
      {
        data: { code: otpNum },
        shareId: id,
        email: emailId,
      },
      {
        onSuccess: (data) => {
          toast(`Email verified successfully!`, {
            type: "success",
            autoClose: 2000,
          });
          if (data.token) {
            localStorage.setItem("shareAccessToken", data.token);
            const decodeValue = decodeShareAuthToken(data.token);
            setShareAuth({
              isShareAuthenticated: true,
              shareAccessToken: data.token,
              shareResourceId: decodeValue.resourceId,
              shareId: decodeValue.id,
              shareCompanyId: decodeValue.companyId,
              shareResourceType: decodeValue.resourceType,
              shareUserMail: decodeValue.emailId,
              shareUserId: decodeValue.userId,
            });
            navigate(`/share/${decodeValue.id}`);
          }
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  };
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto"
            src={"https://equion-dev.s3.us-west-2.amazonaws.com/hissa-logo-dark.png"}
            alt="hissa_logo"
          />
        </div>

        {data ? (
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <div className="flex flex-col items-center justify-center text-center space-y-2">
                <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Email Verification
                </h2>
                <div className="flex flex-row text-sm font-medium text-gray-400">
                  <p>
                    Please enter the email to recieve otp to access the
                    resource.
                  </p>
                </div>
              </div>
              <form className="space-y-6" id="emailForm">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={emailId}
                      required
                      onChange={(e) => {
                        const value = e?.target.value ?? "";
                        setEmailId(value);
                      }}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    onClick={(event) => {
                      event.preventDefault();
                      sendOtp();
                    }}
                    className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Send OTP
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
              <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                <div className="flex flex-col items-center justify-center text-center space-y-2">
                  <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Enter OTP
                  </h2>
                  <div className="flex flex-row text-sm font-medium text-gray-400">
                    <p>We have sent a OTP to your email {emailId}</p>
                  </div>
                </div>
                <form className="space-y-6" id="otpForm">
                  <div>
                    <label
                      htmlFor="otp"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      OTP
                    </label>
                    <div className="mt-2">
                      <input
                        id="otp"
                        name="otp"
                        required
                        value={otpNumber}
                        onChange={(e) => {
                          const value = e.target.value ?? "";
                          setOtpNumber(value);
                          if (value.length === 6) {
                            sendCode(value);
                          }
                        }}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      onClick={(event) => {
                        event.preventDefault();
                        sendCode(otpNumber);
                      }}
                      className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Verify Code
                    </button>
                  </div>
                  <HStack className="justify-between">
                    <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                      <p>Didn&apos;t recieve otp?</p>{" "}
                      <a
                        className="flex flex-row items-center text-blue-600 cursor-pointer"
                        onClick={() => {
                          setOtpNumber("");
                          sendOtp();
                        }}
                      >
                        Resend
                      </a>
                    </div>
                    <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                      <a
                        className="flex flex-row items-center text-blue-600 cursor-pointer underline"
                        onClick={() => {
                          setOtpNumber("");
                          setEmailId("");
                          setData(true);
                        }}
                      >
                        Change Email
                      </a>
                    </div>
                  </HStack>
                </form>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </>
  );
}
